<template>
  <div class="task-list">
    <div class="task-list__header">
      <div class="task-list__row d-flex">
        <div class="flex-grow-1">Tasks</div>
        <div>Duration</div>
      </div>
    </div>
    <div class="task-list__body">
      <div
        v-for="task in items"
        :key="task.id"
        class="task-list__row d-flex p-4 align-items-end border-bottom animate__animated animate__bounceInLeft animate__slow m-cursor-pointer"
        @click="openTask(task)"
      >
        <div class="task-list__data flex-grow-1 mr-3">
          <div class="d-flex mb-3">
            <button class="btn btn-timer-action mr-3" @click.stop="toggleTask()">
              <PlayPauseIcon variant="primary" :type="randomType()" />
            </button>
            <span>
              {{ task.task }}, <strong>{{ task.date }}</strong>
            </span>
          </div>
          <div class="pl-5">
            <div class="progress" style="height: 10px" :title="task.duration">
              <div
                class="progress-bar"
                role="progressbar"
                :style="{ width: '25%' }"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
        <div class="task-list__data">
          {{ task.duration }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlayPauseIcon from "../../components/svg/PlayPauseIcon.vue";

export default {
  name: "TaskList",
  components: {
    PlayPauseIcon,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      types: ["play", "pause"],
    };
  },
  methods: {
    randomType() {
      return this.types[Math.ceil(Math.random() * 2) - 1];
    },
    openTask(task) {
      this.$emit("opened", task);
    },
    toggleTask() {
      //
    },
  },
};
</script>
<style lang="scss" scoped>
.task-list {
  &__header {
    font-size: $regular-size;
    color: $grey-tint-2;
    padding: 1.375rem 2.187rem;
    border-bottom: 1px solid $grey-tint-3;
  }
  &__body {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    .task-list__data:last-child {
      font-size: $regular-size;
    }
  }
}
</style>
