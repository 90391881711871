<template>
  <div class="time-adder card p-3">
    <form class="d-flex justify-content-between align-items-center overflow-auto">
      <div class="mr-3 m-min-w-400">
        <b-form-input
          v-model="name"
          placeholder="What are you working on?"
        ></b-form-input>
      </div>
      <div class="m-min-w-200 mr-3">
        <b-form-select v-model="project_id" :options="filteredProjects"></b-form-select>
      </div>
      <div
        class="time-adder__tenure m-min-w-300 d-flex justify-content-between align-items-center"
      >
        <b-form-input v-model="from" placeholder="9:00 AM"></b-form-input>
        <strong>-</strong>
        <b-form-input v-model="to" placeholder="4:00 PM" class="mr-3"></b-form-input>
        <MDateInput v-model="date" />
      </div>
      <div class="time-adder__actions">
        <button
          class="btn btn-timer-action mr-2"
          title="Start"
          :disabled="disableAddBtn"
          type="button"
          @click="addTime"
        >
          <PlayPauseIcon />
        </button>
        <button
          class="btn btn-timer-action mr-2"
          type="button"
          title="Put a modal here to explain usage"
        >
          <InfoIcon />
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import MDateInput from "./MDateInput.vue";
import PlayPauseIcon from "../svg/PlayPauseIcon.vue";
import InfoIcon from "../svg/InfoIcon.vue";

export default {
  name: "TimeAdder",
  components: {
    MDateInput,
    PlayPauseIcon,
    InfoIcon,
  },
  props: {
    projects: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      name: "",
      project_id: "",
      from: "",
      to: "",
      date: "",
    };
  },
  computed: {
    filteredProjects() {
      return [{ value: "", text: "Please select a project" }, ...this.projects];
    },
    disableAddBtn() {
      return this.name === "" || this.date === "";
    },
  },
  methods: {
    addTime() {
      this.$emit("added", {
        task: this.name,
        duration: "3hrs 10mins",
        date: "Jun 10, 2022",
        id: Math.ceil(Math.random() * 700000),
      });
      this.name = this.date = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.time-adder {
  border-radius: $size-10;
  &__tenure {
    .form-control {
      height: 25px;
      width: 56px;
      font-size: 0.75rem;
      line-height: 1rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      border: 1px solid $grey-tint-3;
      &[type="date"] {
        width: 130px;
        border: none;
      }
    }
  }
  &__actions {
    min-width: 70px;
  }
}
</style>
