<template>
  <div class="timesheet">
    <div class="dashboard-summary">
      <TimeWorked :daily-tracks="dailyTracks" title="Time Worked" />
    </div>
    <div class="dashboard-content py-4">
      <TimeAdder @added="addToList" />
      <div class="timesheet__list mt-4">
        <TaskList :items="items" @opened="openTask" />
        <div
          class="m-pagination overflow-auto d-flex p-4 justify-cotnent-between align-items-center"
        >
          <span class="flex-grow-1">
            Showing page {{ currentPage }} of {{ totalRows }}
          </span>
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            align="right"
            pills
          >
          </b-pagination>
        </div>
      </div>
    </div>
    <TaskModal :task="openedTask" />
  </div>
</template>
<script>
import TimeWorked from "../../components/timesheet/TimeWorked";
import TimeAdder from "../../components/timesheet/TimeAdder";
import TaskList from "../../components/timesheet/TaskList.vue";
import TaskModal from "../../components/timesheet/TaskModal.vue";

export default {
  name: "Timesheet",
  components: {
    TimeWorked,
    TimeAdder,
    TaskList,
    TaskModal,
  },
  data() {
    return {
      isBusy: false,
      perPage: 3,
      currentPage: 1,
      openedTask: {},
      fields: ["task", "duration"],
      items: [
        {
          id: 1,
          task: "Task Name Here",
          duration: "2hrs 8mins", //should be in duration then converted
          date: "May 11, 2020",
        },
        {
          id: 2,
          task: "Another just here",
          duration: "3hrs 8mins", //should be in duration then converted
          date: "May 11, 2020",
        },
        {
          id: 3,
          task: "Task Name Here",
          duration: "2hrs 8mins", //should be in duration then converted
          date: "May 11, 2020",
        },
        {
          id: 4,
          task: "Another just here",
          duration: "3hrs 8mins", //should be in duration then converted
          date: "May 11, 2020",
        },
        {
          id: 5,
          task: "Another just here",
          duration: "3hrs 8mins", //should be in duration then converted
          date: "May 11, 2020",
        },
      ],
      dailyTracks: [
        {
          day: "Thursday",
          date: "January 01, 2022",
          duration: 4000,
        },
        {
          day: "Friday",
          date: "January 02, 2022",
          duration: 3005,
        },
        {
          day: "Monday",
          date: "January 04, 2022",
          duration: 3000,
        },
        {
          day: "Thursday",
          date: "January 01, 2022",
          duration: 40000,
        },
        {
          day: "Friday",
          date: "January 02, 2022",
          duration: 30000,
        },
        {
          day: "Monday",
          date: "January 04, 2022",
          duration: 3000,
        },
        {
          day: "Thursday",
          date: "January 01, 2022",
          duration: 40000,
        },
        {
          day: "Friday",
          date: "January 02, 2022",
          duration: 30000,
        },
        {
          day: "Monday",
          date: "January 04, 2022",
          duration: 3000,
        },
        {
          day: "Thursday",
          date: "January 01, 2022",
          duration: 40000,
        },
        {
          day: "Friday",
          date: "January 02, 2022",
          duration: 30000,
        },
        {
          day: "Monday",
          date: "January 04, 2022",
          duration: 3000,
        },
      ],
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    addToList(item) {
      this.items.unshift(item);
    },
    openTask(task) {
      this.openedTask = task;
      this.$bvModal.show("task_modal");
    },
  },
};
</script>
<style lang="scss">
.timesheet {
  &__list {
    background-color: $body-bg;
    border-radius: $size-10;
    border: 1px solid $grey-tint-3;
  }
}
</style>
