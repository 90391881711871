<template>
  <b-modal id="task_modal" modal-class="task-modal" hide-footer>
    <template #modal-title>
      <span
        class="task-modal__icon d-block d-flex align-items-center justify-content-center"
      >
        <CalendarIcon />
      </span>
    </template>
    <p class="mb-5">
      <span class="d-block task-modal__title">Friday</span>
      <strong class="task-modal__date">{{ task.date }}</strong>
    </p>
    <p class="mb-5">
      <span class="d-block task-modal__title mb-2">Task Name</span>
      <span class="task-modal__task">{{ task.task }}</span>
    </p>
    <div>
      <span class="d-block task-modal__title mb-2">Time spent on task</span>
      <div class="w-25">
        <div class="progress" style="height: 10px" :title="task.duration">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: '25%' }"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <span>{{ task.duration }}</span>
      </div>
    </div>
  </b-modal>
</template>
<script>
import CalendarIcon from "../svg/CalendarIcon";
export default {
  name: "TaskModal",
  components: {
    CalendarIcon,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.task-modal {
  &__icon {
    background-color: $grey-tint-3;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  &__date {
    color: $dark-blue;
    font-size: 1.125rem;
  }
  &__title {
    color: $faint-blue;
  }
  &__task {
    color: $dark-blue;
    font-size: 1.125rem;
  }
}
</style>
