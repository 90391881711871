<template>
  <b-card class="time-worked" :title="title" title-tag="h2">
    <div class="time-worked__tracked-time d-flex align-items-end">
      <div class="mt-1 mr-3">
        <h3 class="text-primary">135 hrs 3 mins</h3>
      </div>
      <div class="mr-3">
        <span class="fs-5">Tracked time</span>
      </div>
      <div class="flex-grow-1 pb-1">
        <div
          class="progress"
          style="height: 10px; max-width: 300px"
          title="135 hrs 3 mins"
        >
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: trackedPercentage + '%' }"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
    <div class="time-worked__daily-tracks d-flex overflow-auto">
      <DailyTracker v-for="(daily, i) in dailyTracks" :key="i" :tracked-data="daily" />
    </div>
  </b-card>
</template>
<script>
import DailyTracker from "./DailyTracker.vue";
export default {
  components: {
    DailyTracker,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    dailyTracks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    trackedPercentage() {
      return 20;
    },
  },
};
</script>
<style lang="scss" scoped>
.time-worked {
  border-radius: $size-10;
  h2 {
    font-size: 1.875rem;
    font-weight: bold;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }
  &__daily-tracks {
    padding: 30px 0 20px 0;
  }
}
</style>
