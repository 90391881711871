<template>
  <svg
    v-if="showPlaySecondary"
    key="playSecondary"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="#68737E" />
    <path
      d="M16.5655 12.3301L10.2644 8.13643C9.72704 7.77818 9 8.16804 9 8.81079V17.1876C9 17.8409 9.72704 18.2202 10.2644 17.862L16.5655 13.6683C17.0396 13.3627 17.0396 12.6462 16.5655 12.3301Z"
      fill="white"
    />
  </svg>
  <svg
    v-else-if="showPause"
    key="pause"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="#999C9F" />
    <path
      d="M16 7.74473C16 7.33343 15.6437 7 15.2041 7C14.7646 7 14.4083 7.33343 14.4083 7.74473V18.1709C14.4083 18.5822 14.7646 18.9156 15.2041 18.9156C15.6437 18.9156 16 18.5822 16 18.1709V7.74473Z"
      fill="white"
    />
    <path
      d="M11.5917 7.82909C11.5917 7.41779 11.2354 7.08436 10.7959 7.08436C10.3563 7.08436 10 7.41779 10 7.82909V18.2553C10 18.6666 10.3563 19 10.7959 19C11.2354 19 11.5917 18.6666 11.5917 18.2553V7.82909Z"
      fill="white"
    />
  </svg>
  <svg
    v-else
    key="playPrimary"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="#08569D" />
    <path
      d="M16.5655 12.3301L10.2644 8.13643C9.72704 7.77818 9 8.16804 9 8.81079V17.1876C9 17.8409 9.72704 18.2202 10.2644 17.862L16.5655 13.6683C17.0396 13.3627 17.0396 12.6462 16.5655 12.3301Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "PlayPauseIcon",
  props: {
    variant: {
      type: String,
      default: "secondary",
      validator(value) {
        return ["secondary", "primary"].includes(value);
      },
    },
    type: {
      type: String,
      default: "play",
      validator(value) {
        return ["play", "pause"].includes(value);
      },
    },
  },
  computed: {
    showPlaySecondary() {
      return this.variant === "secondary" && this.type === "play";
    },
    showPlayPrimary() {
      return this.variant === "primary" && this.type === "play";
    },
    showPause() {
      return this.type === "pause";
    },
  },
};
</script>
